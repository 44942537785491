import {BaseGraph} from "../base_graph";

class DrivenThisWeek extends BaseGraph {
    constructor() {
        super('driven_this_week');
    }

    getChartJsOptions(options, data) {
        options.indexAxis = 'y';
        options.plugins = {
            legend: {
                display: false
            },
            annotation: {
                annotations: {
                    fiftysix: {
                        type: 'line',
                        borderColor: '#F28900',
                        value: 56,
                        xMin: 56,
                        xMax: 56,
                    }
                }
            }
        };
        return options;
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new DrivenThisWeek();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});